import { ApiClients } from '@roolz/api/clients'
import {
  CheckNicknameBusynessRequest,
  CheckNicknameBusynessResponse,
  ClientConfigResponse,
  SendSmsRequest,
  SendSmsResponse,
  SendSmsToChangePhoneRequest, SendSmsToChangePhoneResponse
} from '@roolz/types/api/profiles'
import { PointsTimezonesResponse, SendEventRequest } from '@roolz/types/api/system'
import { AxiosInstance } from 'axios'
import { retryRequest } from '../../utils/retryRequest'

export class SystemApi {
  identity: AxiosInstance
  events: AxiosInstance
  messaging: AxiosInstance
  hereProxy: AxiosInstance

  constructor(clients: ApiClients) {
    this.identity = clients.identity
    this.events = clients.events
    this.messaging = clients.messaging
    this.hereProxy = clients.hereProxy
  }

  detectMyCountry() {
    return retryRequest(() => this.identity.get('/v1/public/configuration/country/auto'), {
      attempts: 5
    })
  }

  getCountryList(locale?: string) {
    const headers: Record<string, string> = {}

    if(locale) headers['Accept-Language'] = locale

    return retryRequest(() => this.identity.get('/v1/public/configuration/country/list', { headers }), {
      attempts: Infinity,
      delay: 1500
    })
  }

  checkNicknameBusyness(params: CheckNicknameBusynessRequest) {
    return this.identity.get<CheckNicknameBusynessResponse>('/v1/public/profile/check_nickname', { params })
  }

  sendSms(captchaToken: string, body: SendSmsRequest) {
    return this.identity.post<SendSmsResponse>('/v1/public/auth/login/phone/send_code', body, {
      headers: {
        'Recaptcha-Token': captchaToken
      }
    })
  }
  sendSmsToChangePhone(body: SendSmsToChangePhoneRequest) {
    return this.identity.post<SendSmsToChangePhoneResponse>('/v1/private/me/profile/update_auth_phone/send_code', body)
  }
  getClientConfig() {
    return this.identity.get<ClientConfigResponse>('/v1/private/configuration/client_config')
  }

  getGeoPointsTimezone(params: {
    point: string[]
  }) {
    return this.hereProxy.get<PointsTimezonesResponse>('/mapgeo/v1/geo/timezone', {
      params
    })
  }
  sendEvent(body: SendEventRequest) {
    return this.events.post('/v1/send', body)
  }
}
