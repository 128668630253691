import { api } from '@/api'
import { bus } from '@/events'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { bidsStore } from '@/store/bids/bids.store'
import { companiesStore } from '@/store/companies/companies.store'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { myOffersStore } from '@/store/exchange/my_offers.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import {
  CreateDuplicatesRequest,
  CreateOfferRequest,
  CreateTemplateRequest, GetMyOffersBody,
  GetMyOffersParams, GetOfferMatchesRequest, GetOfferRequest, GetPrivateOffersRequest,
  GetPublicOffersRequest,
  GetTemplatesRequest,
  Offer,
  OfferStatus,
  OfferType,
  UpdateOfferRequest
} from '@roolz/types/api/exchange'
import { cloneDeep } from 'lodash-es'

const updateOfferStoresByStatus = (newOffer: Offer, oldOffer?: Offer) => {
  exchangeStore.removeOffer(newOffer)
  exchangeStore.addOrUpdateOffer(newOffer)
  myOffersStore.updateOffer(newOffer)

  if(oldOffer) bus.emit('offers/updated', [oldOffer, newOffer])
}

export class ExchangeService {
  private showConfetti() {
    const count = 200,
      defaults = {
        origin: { y: 0.7 }
      }

    function fire(particleRatio: any, opts: any) {
      // @ts-ignore
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        })
      )
    }

    function run() {
      fire(0.25, {
        spread: 26,
        startVelocity: 55
      })

      fire(0.2, {
        spread: 60
      })

      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      })

      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      })

      fire(0.1, {
        spread: 120,
        startVelocity: 45
      })
    }

    setTimeout(() => {
      run()
      setTimeout(run, 150)
    }, 500)
  }

  createOffer(body: CreateOfferRequest) {
    return api.exchange.createOffer(body)
      .then(async response => {
        const { data: offer, headers } = response

        if(headers?.['total-offers'] === '1') {
          this.showConfetti()
        }

        const relatedId = offer.partners_selection_dependent_offer_id
        const related = relatedId ? await api.exchange.getOffer({ id: relatedId }) : null

        if(related) {
          exchangeStore.addOrUpdateOffer(related.data.result)
          myOffersStore.addOfferByStatus(related.data.result)
        }

        exchangeStore.addOrUpdateOffer(offer)
        myOffersStore.addOfferByStatus(offer)


        bus.emit('offers/created', offer)

        return offer
      })
  }

  async updateOffer(params: UpdateOfferRequest) {
    const oldOffer = cloneDeep(exchangeStore.findOffer(params.id))
    const linkedOfferId = oldOffer?.partners_selection_dependent_offer_id || oldOffer?.partners_selection_original_offer_id
    const isRelated = oldOffer?.is_partners_selection || oldOffer?.is_partners_selection_dependent_offer

    const oldRelated = linkedOfferId && isRelated ? await api.exchange.getOffer({ id: linkedOfferId }) : null

    return api.exchange.editOffer(params)
      .then(async ({ data: offer }) => {
        if(linkedOfferId && oldRelated) {
          const { data: { result: relatedOffer } } = await api.exchange.getOffer({ id: linkedOfferId })

          updateOfferStoresByStatus(relatedOffer, oldRelated.data.result)
        }

        exchangeStore.removeOffer(offer)
        exchangeStore.addOrUpdateOffer(offer)

        if(offer.status === OfferStatus.DELETED) {
          myOffersStore.removeOfferByPrevStatus(offer)
        }

        if(oldOffer) {
          bus.emit('offers/updated', [oldOffer, offer])
        }

        return offer
      })
  }

  async updateOfferStatus(offer: UpdateOfferRequest) {
    const oldOffer = cloneDeep(exchangeStore.findOffer(offer.id))
    const oldRelatedOffer = cloneDeep(exchangeStore.findOffer(offer.id))

    const { data: newOffer } = await api.exchange.editOffer(offer)

    const linkedOfferId = newOffer.partners_selection_dependent_offer_id || newOffer.partners_selection_original_offer_id
    const isRelated = newOffer.is_partners_selection || newOffer.is_partners_selection_dependent_offer

    if(linkedOfferId && isRelated) {
      const { data: { result: relatedOffer } } = await api.exchange.getOffer({ id: linkedOfferId })

      updateOfferStoresByStatus(relatedOffer, oldRelatedOffer)
    }

    updateOfferStoresByStatus(newOffer, oldOffer)

    return newOffer
  }

  loadCargoTemplates(params?: GetTemplatesRequest) {
    api.exchange.getTemplates({
      ...params,
      type: OfferType.CARGO,
      records: 100,
      active_space_company_id: profilesStore.activeCompanyId ?? null
    })
      .then(({ data }) => {
        exchangeStore.cargoTemplates = data.result ?? []
        exchangeStore.isCargoTemplatesLoaded = true
      })
      .catch(console.log)
  }

  loadTransportTemplates(params?: GetTemplatesRequest) {
    api.exchange.getTemplates({
      ...params,
      type: OfferType.TRANSPORT,
      records: 100,
      active_space_company_id: profilesStore.activeCompanyId ?? null
    })
      .then(({ data }) => {
        exchangeStore.transportTemplates = data.result ?? []
        exchangeStore.isTransportTemplatesLoaded = true
      })
      .catch(console.log)
  }

  createTemplate(params: CreateTemplateRequest) {
    return api.exchange.createTemplate(params)
      .then(({ data }) => {
        if(data.body.offer_type === OfferType.CARGO) {
          exchangeStore.cargoTemplates.unshift(data)
        }
        if(data.body.offer_type === OfferType.TRANSPORT) {
          exchangeStore.transportTemplates.unshift(data)
        }

        return data
      })
  }

  deleteCargoTemplate(id: string) {
    return api.exchange.deleteTemplate(id)
      .then(() => {
        exchangeStore.removeCargoTemplate(id)
      })
  }

  deleteTransportTemplate(id: string) {
    return api.exchange.deleteTemplate(id)
      .then(() => {
        exchangeStore.removeTransportTemplate(id)
      })
  }

  loadMyOffers(params: GetMyOffersParams, body?: GetMyOffersBody) {
    return api.exchange.getMyOffers(params, body)
      .then(({ data }) => {
        if(data.result?.length) data.result.forEach(exchangeStore.addOrUpdateOffer)
        if(data.companies?.length) data.companies.forEach(companiesStore.addOrUpdateCompany)
        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        return data
      })
  }

  loadPublicOffers(params: GetPublicOffersRequest) {
    return api.exchange.getAllOffers(params)
      .then(({ data }) => {
        if(data.result?.length) data.result.forEach(exchangeStore.addOrUpdateOffer)
        if(data.companies?.length) data.companies.forEach(companiesStore.addOrUpdateCompany)
        if(data.space_bids?.length) data.space_bids.forEach(bidsStore.addOrUpdateMySpaceBid)
        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        return data
      })
  }

  loadPrivateOffers(params: GetPrivateOffersRequest) {
    return api.exchange.getAllOffers(params)
      .then(({ data }) => {
        if(data.result?.length) data.result.forEach(exchangeStore.addOrUpdateOffer)
        if(data.companies?.length) data.companies.forEach(companiesStore.addOrUpdateCompany)
        if(data.space_bids?.length) data.space_bids.forEach(bidsStore.addOrUpdateMySpaceBid)
        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        return data
      })
  }

  duplicateOffer(params: CreateDuplicatesRequest) {
    return api.exchange.createDuplicates(params)
      .then(({ data: offers }) => {
        offers.forEach(offer => {
          exchangeStore.addOrUpdateOffer(offer)
          myOffersStore.addOfferByStatus(offer)
        })

        bus.emit('offers/duplicated', offers)

        return offers
      })
  }

  loadOffer(params: GetOfferRequest): Promise<Offer> {
    return api.exchange.getOffer(params)
      .then(({ data }) => {
        exchangeStore.addOrUpdateOffer(data.result)

        if(data.company) companiesStore.addOrUpdateCompany(data.company)
        if(data.space_bids?.length) data.space_bids.forEach(bidsStore.addOrUpdateMySpaceBid)
        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        return data.result
      })
  }

  async getOfferGeoState({ _id }: Pick<Offer, '_id'>) {
    const { data: geoState } = await api.exchange.getOfferGeoState({ _id })

    exchangeStore.updateOfferGeoState({ _id }, geoState)

    return geoState
  }

  async getOfferRoute({ _id }: Pick<Offer, '_id'>) {
    const { data: routeWay } = await api.exchange.getOfferRoute({ _id })

    exchangeStore.updateOfferRoute({ _id }, routeWay)

    return routeWay
  }

  loadOfferMatches(params: GetOfferMatchesRequest) {
    return api.exchange.getOfferMatches(params)
      .then(({ data }) => {
        if(params.exact) {
          exchangeStore.updateOfferPartial({
            _id: params.id,
            matches_exact_count: data.total
          })
        } else {
          exchangeStore.updateOfferPartial({
            _id: params.id,
            matches_partial_count: data.total
          })
        }

        data.matches.forEach(offer => exchangeStore.addOrUpdateOffer(offer))

        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        if(data.companies?.length) {
          data.companies.forEach(company => {
            companiesStore.addOrUpdateCompany(company)
          })
        }

        return data
      })
  }
}

export const exchangeService = new ExchangeService()
