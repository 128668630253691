import { api } from '@/api'
import { DebugProvider } from '@/components/_debug/DebugProvider'
import { MessagingControlsProvider } from '@/components/chats/MessagingControlsProvider'
import { MiniChatContainer } from '@/components/chats/MiniChatContainer/MiniChatContainer'
import { DefaultErrorBoundary } from '@/components/DefaultErrorBoundary'
import { AppealModalsContainer } from '@/components/AppealModalsContainer'
import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
import { Authed } from '@/components/middlewares/Authed'
import { StaticToastsController } from '@/components/StaticToastsController'
import { UpdateAppButton } from '@/components/ui/UpdateAppButton/UpdateAppButton'
import { AppLink } from '@/components/utils/AppLink'
import { modalRoutes, resolvePathByName, ROUTE_NAMES, routes as routeMap } from '@/config/routes'
import { ConfirmationContainer } from '@/confirmation/ConfirmationContainer'
import { IdbObservationsProvider } from '@/database/IdbObservationsProvider'
import { GlobalModalsProvider } from '@/global-modals/GlobalModalsProvider'
import { useLastLocation } from '@/hooks/useLastLocation'
import { IS_FILTER_VISIBLE_KEY, MY_OFFERS_FILTER } from '@/pages/home/offers/MyOffers/constants'
import i18n from '@/plugins/i18n'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { clientSettings } from '@/store/client-settings/client-settings'
import { companiesService } from '@/store/companies/companies.service'
import { companiesStore } from '@/store/companies/companies.store'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { privateExchangeOffers, privateExchangeOffersFilter } from '@/store/exchange/private-exchange.offers.store'
import { publicExchangeOffersFilter } from '@/store/exchange/public-exchange.offers.store'
import { knowledgeService } from '@/store/knowledge/knowledge.service'
import { companyKnowledgeStore } from '@/store/knowledge/stores/company-knowledge.store'
import { exchangeKnowledgeStore } from '@/store/knowledge/stores/exchange-knowledge.store'
import { partnershipStore } from '@/store/partnership/partnership.store'
import { contactsService } from '@/store/profiles/contacts.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemService } from '@/store/system/system.service'
import { systemStore } from '@/store/system/system.store'
import { partnershipService } from '@/store/partnership/partnership.service'
import { getNow } from '@/utils/date'
import { useServiceWorker } from '@/workers/service-worker/useServiceWorker'
import { WorkersProvider } from '@/workers/WorkersProvider'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material'
import { SnackbarsContainer, toastError } from '@roolz/sdk/components/snackbars'
import { IosSafariFocusBoxesFix } from '@roolz/sdk/components/utils/IosSafariFocusBoxesFix'
import { defaultTheme } from '@roolz/sdk/config/mui'
import { useWindowVisibilityChange } from '@roolz/sdk/hooks/useWindowVisibilityChange'
import { SdkContextInterface, SdkProvider } from '@roolz/sdk/SdkContext'
import { removeCompanyAfterInvite } from '@roolz/sdk/utils/afterEmailInviteRedirect'
import { IS_MOBILE, IS_SAFARI } from '@roolz/sdk/utils/device'
import { OfferType } from '@roolz/types/api/exchange'
import { withProfiler } from '@sentry/react'
import { useUpdateEffect } from 'ahooks'
import { observer } from 'mobx-react-lite'
import React, { FC, memo, ReactNode, Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { matchRoutes, useLocation, useNavigate } from 'react-router'
import { Link, useRoutes } from 'react-router-dom'
import { MantineProvider, createTheme } from '@mantine/core'
import '@mantine/core/styles.css'
import './App.scss'
import './plugins/gsap'

// TODO move to somewhere where better place for it
declare global {
  interface Window {
    clipboardData: any
    //Here maps sdk loaded via Here-cdn
    H: any
  }

  const H: any
}

const mantineTheme = createTheme({})

const App = observer(function App() {
  const {ready: translationReady, i18n: { language } } = useTranslation([], {
    useSuspense: false
  })
  const { waitingWorker, showReload, reloadPage } = useServiceWorker()

  const isRU = profilesStore.isRu

  useUpdateEffect(() => {
    publicExchangeOffersFilter.reset()
    privateExchangeOffersFilter.reset()
  }, [isRU])

  const sdkConfig: SdkContextInterface = {
    useTranslation: (ns?: string) => {
      const { t, i18n: { language } } = useTranslation(ns)

      return { t, lang: language }
    },

    AppLink,

    Trans: Trans,
    lang: language,
    // Link,
    isRU,
    getServerDatetime: getNow,
    api,
    knowledge: {
      supportedCurrencies: exchangeKnowledgeStore.supportedCurrencies ?? [],
      userCountry: systemStore.userCountry,
      supportedCompanyForms: companyKnowledgeStore.supportedCompanyForms ?? [],
      supportedExchangeRoles: companyKnowledgeStore.supportedExchangeRoles ?? []
    }
  }

  useWindowVisibilityChange(() => {
    systemStore.tabVisibility = document.visibilityState

    if(document.visibilityState === 'visible') {
      chatsService.loadOrUpdateChats()
    }
  })

  if(!translationReady) {
    return null
  }

  return (
    <div className='app'>
      <Suspense>
        {IS_MOBILE && IS_SAFARI && (
          <IosSafariFocusBoxesFix/>
        )}

        <ThemeProvider theme={defaultTheme}>
          <MantineProvider theme={mantineTheme}>
            <SkeletonTheme baseColor='#EFF1F3'>
              <SdkProvider value={sdkConfig}>
                <SnackbarsContainer/>
                <DebugProvider/>
                <Authed>
                  {!systemStore.isLoggingOut && (<><IdbObservationsProvider/>
                    {clientSettings.lastChatsLoadTime !== null && (
                      <WorkersProvider/>
                    )}
                    <AppInit>
                      <StaticToastsController/>
                      <MiniChatContainer/>
                      {IS_MOBILE && (
                        <MessagingControlsProvider/>
                      )}

                      <ClientConfigActualizer/>

                      <DefaultErrorBoundary>
                        {/* On mobile destroy pages if some shat is open */}
                        {(!IS_MOBILE || !chatsStore.activeChatId) && (
                          <PagesContainer/>
                        )}
                      </DefaultErrorBoundary>

                      {(waitingWorker || showReload) && (
                        <UpdateAppButton onUpdate={reloadPage}/>
                      )}

                      <ConfirmationContainer/>
                    </AppInit>
                  </>)}{/*<SWProvider/>*/}
                </Authed>
              </SdkProvider>
            </SkeletonTheme>
          </MantineProvider>
        </ThemeProvider>
      </Suspense>
    </div>
  )
})

const PagesContainer = memo(() => {
  const location = useLocation()
  const { saveLastLocation, getLastLocation } = useLastLocation()

  const fallbackLocation = {
    pathname: resolvePathByName(ROUTE_NAMES.MY_OFFERS),
    search: '',
    hash: '',
    state: {},
    key: ''
  }

  const initialLocation = location.state?.backgroundLocation || getLastLocation() || fallbackLocation
  const isMatchModal = matchRoutes(modalRoutes, location)?.length

  useEffect(() => {
    // save only if it's not modal route
    if(!isMatchModal) {
      if(location?.state?.backgroundLocation) {
        saveLastLocation(location?.state?.backgroundLocation)
      } else {
        delete location?.state?.backgroundLocation
        saveLastLocation(location)
      }
    }
  }, [location])

  const Routes = useRoutes(routeMap, isMatchModal ? initialLocation : location)
  const ModalRoutes = useRoutes(modalRoutes)

  return (
    <GlobalModalsProvider>
      {Routes}
      {ModalRoutes}
    </GlobalModalsProvider>
  )
})

const ClientConfigActualizer = () => {
  const lastDate = useRef(new Date())
  const interval = useRef<any>(null)

  useEffect(() => {
    // Detect when user change date on the device to recalculate client-server UTC date diff
    interval.current = setInterval(() => {
      const timeDiff = new Date().getTime() - lastDate.current.getTime()

      if(Math.abs(timeDiff) >= 5000) { // Five second leniency
        systemService.syncClientConfig()
      }

      lastDate.current = new Date()
    }, 1000)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return <></>
}

function AppInit({ children }: {
  children: ReactNode
}) {
  const [dependenciesLoaded, setDependenciesLoaded] = useState<boolean>(false)
  const [ready, setReady] = useState<boolean>(false)


  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if(ready) {
      // @ts-ignore
      window.hideLoader()
    }
  }, [ready])

  useEffect(() => {
    if(dependenciesLoaded) {
      if(!profilesStore.isMyProfileFilled && location.pathname !== resolvePathByName(ROUTE_NAMES.FILL_PROFILE)) {
        /**
         * Remove after invite cookie for new user
         * */
        removeCompanyAfterInvite()
        /**
         * If profile of user have no necessary data in profile, redirect to filling page
         */
        navigate(resolvePathByName(ROUTE_NAMES.FILL_PROFILE))
      } else if(clientSettings.isJustSignedUp &&
        profilesStore.my_profile &&
        !profilesStore.my_profile.companies?.length
      ) {
        if(location.pathname !== resolvePathByName(ROUTE_NAMES.COMPANY_CREATE)) {
          navigate(resolvePathByName(ROUTE_NAMES.COMPANY_CREATE), { replace: true })
        }
      } else if(location.pathname === resolvePathByName(ROUTE_NAMES.HOME)) {
        /**
         * Default route
         */
        navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE), { replace: true })
      }

      setTimeout(() => {
        setReady(true)
      })
    }
  }, [location, dependenciesLoaded])

  useUpdateEffect(() => {
    try {
      const activeSpaceCompanyId = profilesStore?.my_profile?.active_space_company_id

      localStorage.removeItem(MY_OFFERS_FILTER)
      localStorage.removeItem(IS_FILTER_VISIBLE_KEY)
      companiesStore.resetState()
      partnershipStore.resetState()
      activeSpaceCompanyId && partnershipService.getPartners(activeSpaceCompanyId)

      if(location.pathname === resolvePathByName(ROUTE_NAMES.COMPANY_CREATE)) {
        return
      }

      window.location.reload()
    } catch(e: any) {
      //@ts-ignore
      toastError(i18n.t('errors:insufficient_request'))
    }
  }, [profilesStore?.my_profile?.active_space_company_id])

  async function loadDependencies() {
    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id

    const blockingDependencies = [
      systemService.loadCountries(true),
      companiesService.loadBusinessClients(),
      companiesService.loadMyCompanies(),
      companiesService.loadCurrentCompanyInternalInfo(),
      knowledgeService.loadCompanyCategories()
    ]
    const asyncDependencies = [
      // knowledgeService.loadRequiredDictionaries(),
      contactsService.loadContactsAndBans(false),
      // knowledgeService.loadExchangeDictionaries(),
      // knowledgeService.loadCompanyDictionaries(),
      systemService.detectUserCountry(),
      chatsService.loadOrUpdateChats()
    ]

    if(activeSpaceCompanyId) {
      asyncDependencies.push(partnershipService.getPartners(activeSpaceCompanyId))
      asyncDependencies.push(partnershipService.getIncomingInvitationsCount(activeSpaceCompanyId))
    }

    Promise.all(blockingDependencies).then(() => {
      setDependenciesLoaded(true)
    })
    Promise.allSettled(asyncDependencies)
  }

  const onProfileFilledChange = async () => {
    await clientSettings.init()

    knowledgeService.loadRequiredDictionariesList()

    if(profilesStore.isMyProfileFilled) {
      loadDependencies()
    } else {
      Promise.all([
        systemService.loadCountries(true),
        systemService.detectUserCountry(),
      ]).then(() => {
        setDependenciesLoaded(true)
      })
    }
  }

  useLayoutEffect(() => {
    onProfileFilledChange()
  }, [profilesStore.isMyProfileFilled])

  useEffect(() => {
    if(location.pathname === resolvePathByName(ROUTE_NAMES.PRIVATE_EXCHANGE_IN)) return
    if(location.pathname === resolvePathByName(ROUTE_NAMES.PRIVATE_EXCHANGE)) return

    privateExchangeOffers.checkNewIncomingOffers(OfferType.CARGO)
    privateExchangeOffers.checkNewIncomingOffers(OfferType.TRANSPORT)
  }, [])

  if(!ready) {
    return <PageLoading/>
  }

  return (
    <>{children}</>
  )
}

const AppWithSuspense = () => {
  return (
    <Suspense>

      <App/>
    </Suspense>
  )
}

export default withProfiler(AppWithSuspense)
