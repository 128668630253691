import { makeAutoObservable } from 'mobx'
import { getNow } from '@/utils/date'
import { Country, UserCountry } from '@roolz/types/custom'

class SystemStore {
  private _clientServerDateDiffMs: number

  online = navigator.onLine
  isLoggingOut = false
  tabVisibility = document.visibilityState

  isJivositeLoaded = false
  isAppealsModalControllerEnabled = true

  serverTime10secUpdate: number

  userCountry: UserCountry
  countries: Country[]

  constructor() {
    makeAutoObservable(this)
  }

  get clientServerDateDiffMs() {
    return this._clientServerDateDiffMs
  }

  set clientServerDateDiffMs(value: number) {
    this._clientServerDateDiffMs = value
    this.serverTime10secUpdate = getNow().getTime()
  }

  setCountries(countries: Country[]): void {
    countries.sort((a: Country, b: Country) => {
      return a.name.localeCompare(b.name)
    })

    this.countries = countries
  }

  setUserCountry(country: UserCountry): void {
    this.userCountry = country
  }
}

const systemStore = new SystemStore()

setInterval(() => {
  systemStore.serverTime10secUpdate = getNow().getTime()
}, 10 * 1000)

// window.addEventListener('online', () => systemStore.online = navigator.onLine)
// window.addEventListener('offline', () => systemStore.online = navigator.onLine)

export {
  SystemStore,
  systemStore
}
