import { NoMessages } from '@roolz/icons/chats/NoMessages'
import { useTranslation } from 'react-i18next'
import styles from './EmptyChatPlaceholder.module.scss'

export const EmptyChatPlaceholder = () => {
  const { t } = useTranslation('chat/message')

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <NoMessages/>

        <h2 className={styles.title}>
          {t('empty_chat_placeholder.title')}
        </h2>

        <div className={styles.list}>
        <span className={styles.list__item__text}>
        {t('empty_chat_placeholder.description')}
        </span>
        </div>
      </div>
    </div>
  )
}
