import { Message } from '@roolz/types/api/chats'
import { useMemo } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MessageDate.module.scss'
import dayjs from '@roolz/sdk/plugins/dayjs'

interface Props {
  message: Message
}

export const MessageDate = ({
  message
}: Props) => {
  const { t } = useTranslation('ui')

  const dateFormatted = useMemo(() => {
    const date = dayjs(message.edited_at ?? message.created_at)

    if(date.isToday()) {
      return date.format('HH:mm')
    }
    if(date.isYesterday()) {
      return t('datetime.yesterday')
    }

    if(dayjs().startOf('day').diff(date.startOf('day'), 'days') < 7) {
      const indexToLocaleMap = {
        0: 'sunday',
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday',
      }

      // @ts-ignore
      return t('datetime.week_days.' + indexToLocaleMap[date.day()])
    }

    return date.format('DD.MM.YY')
  }, [message.created_at, message.edited_at])

  return (
    <div className={styles.date}>
      {dateFormatted}
    </div>
  )
}
