import { api } from '@/api'
import { getProfileById } from '@/repositories/profiles.repository'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { companyKnowledgeStore } from '@/store/knowledge/stores/company-knowledge.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import {
  Company, GetAllPublicCompanies,
  GetCompanyRequest,
  SendCompanyInvitationRequest,
  UpdateCompanyRequest
} from '@roolz/types/api/companies'
import { Profile } from '@roolz/types/api/profiles'
import { AxiosResponse } from 'axios'

const myCompanyRequestWrapper = (promise: Promise<AxiosResponse<Company, any>>) => {
  companiesStore.isMyCompanyLoading = true

  return promise
    .then(({ data }) => {
      companiesStore.addOrUpdateCompany(data)

      const myCompany = companiesStore.findCompany(data.id)
      companiesStore.myCompany = myCompany ?? null

      return data
    })
    .catch(e => {
      throw e
    })
    .finally(() => (companiesStore.isMyCompanyLoading = false))
}

class CompaniesService {
  getMyCompanyById({ id }: GetCompanyRequest) {
    return myCompanyRequestWrapper(api.companies.getCompanyById(id))
  }

  updateCompany(company_id: string, body: UpdateCompanyRequest) {
    if(body.exchange_role) {
      const role = companyKnowledgeStore.supportedExchangeRoles.find(role => role.slug === body.exchange_role)

      if(role?.cargo_type_active === false) body.cargo_type = null
      if(role?.customs_service_active === false) body.customs_service = null
      if(role?.fleet_amount_active === false) body.fleet_amount = null
      // both body fields relate on "international_direction_active" (knowledge config problem)
      if(role?.international_direction_active === false) body.national_direction = null
      if(role?.international_direction_active === false) body.international_direction = null
      if(role?.transportation_type_active === false) body.transportation_type = null
    }

    return myCompanyRequestWrapper(api.companies.updateCompany(company_id, body))
  }

  async getCompanyByNickname({ nickname }: { nickname: string }) {
    const { data } = await api.companies.getCompanyByNickname(nickname)
    companiesStore.addOrUpdateCompany(data)

    const invitationCreatorId = data?.partnership_invitation?.creator_profile_id

    if(invitationCreatorId) {
      await getProfileById(invitationCreatorId)
    }

    return data
  }

  async getAllCompanies({ params, body }: GetAllPublicCompanies) {
    return api.companies.getAllCompanies({
      params: {
        order_by: '-offers_count',
        ...params
      }, body
    })
      .then(({ data }) => {
        data.items.forEach((company: Company) => {
          companiesStore.addOrUpdateCompany(company)
        })

        return data
      })
  }

  async loadMyCompanies() {
    return api.companies.getOwnCompanies()
      .then(({ data }) => {
        data.forEach((company: Company) => {
          companiesStore.addOrUpdateCompany(company)
        })
        myCompaniesStore.companies = data

        return data
      })
  }

  async loadCurrentCompanyInternalInfo() {
    const companyId = profilesStore?.my_profile?.active_space_company_id

    if(!companyId) {
      return
    }

    return api.companies.getInternalCompanyInfo(companyId)
      .then(({ data }) => {
        myCompaniesStore.currentCompanyInternalInfo = data
      })
      .catch(console.error)
  }

  async loadBusinessClients() {
    const clientList = await api.companies.loadAllBusinessClientsInfo()

    companiesStore.businessClientMap = clientList.data
  }

  sendCompanyInvitation(companyId: Company['id'], body: SendCompanyInvitationRequest) {
    return api.companies.sendInvitation(companyId, body)
  }

  addRepresentative(company_id: Company['id'], profile_id: Profile['id']) {
    return api.companies.addRepresentative(company_id, profile_id)
  }

  deleteRepresentative(company_id: Company['id'], profile_id: Profile['id']) {
    return api.companies.deleteRepresentative(company_id, profile_id)
  }

  getRepresentatives(company_id: Company['id']) {
    return api.companies.getRepresentatives(company_id)
  }

  getPublicRepresentatives(company_id: Company['id']) {
    return api.companies.getPublicRepresentatives(company_id)
  }
}

export const companiesService = new CompaniesService()
