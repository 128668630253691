import { CargoTypeSlug } from '@roolz/types/api/knowledge/exchange'
import {
  Partnership,
  PartnershipInvitation,
  PartnershipInvitationsCounters
} from '@roolz/types/api/partnership'
import { PaginationWithPage, ItemsResponse, Pagination } from '@roolz/types/api/utils'
import {
  CompanyCargoTypeSlug,
  CompanyEmployeeAmountSlug,
  CompanyFormSlug,
  CustomsServiceSlug,
  ExchangeRoleSlug, FleetAmountSlug,
  LogisticsServiceSlug,
  OrderAmountSlug, TransportationTypeSlug
} from '../api/knowledge/companies'
import { AuthDevice, CompanyInvitation, Profile } from './profiles'
import { Country } from '../custom'
import { ID, Url } from '../scalars'


export enum CompanyRoles {
  owner = 'owner',
  admin = 'admin',
  member = 'member'
}

export interface Company {
  id: ID,
  nickname?: string
  owner_id: Profile['id'],
  momo?: string
  color?: string
  logo_path?: Url | null
  cover_path?: Url | null
  custom_cover_path?: Url | null
  goal?: string[]
  employee_amount?: string | null
  order_amount?: OrderAmountSlug | null
  fleet_amount?: string
  phone?: string
  email?: string
  website?: string
  description?: string
  name?: string
  legal_name?: string
  legal_form_short?: string
  tax_number?: string
  registration_number?: string
  legal_address?: string
  registration_date?: string
  mail_address?: string
  associations?: string[]
  foundation_year?: number
  transportation_type?: string[]
  company_form?: CompanyFormSlug //
  exchange_role?: ExchangeRoleSlug
  cargo_type?: CargoTypeSlug[]
  logistics_service?: LogisticsServiceSlug[]
  customs_service?: CustomsServiceSlug[]
  international_direction?: Country['alpha2'][]
  national_direction?: Country['alpha2'][]
  is_profile_public?: boolean
  registration_country?: Country
  is_transport_license?: boolean
  is_transport_license_company?: boolean
  transport_license_number?: string
  is_forwarder_license?: boolean
  is_forwarder_license_company?: boolean
  forwarder_license_number?: string
  offers_count?: number | null
  my_role?: CompanyRoles
  partnership_invitation: PartnershipInvitation | null
  partnership: Partnership | null
  partnership_counters: PartnershipInvitationsCounters | null
}

export interface PublicCompanies {
  public_companies_list: Company[]
  total_company_count: number
}

export interface GetCompanyContacts {
  department_id?: string
  excluding_profile_ids?: string[]
  search_query?: string
  ordering?: string
  offset?: number
  limit?: number
}


export interface CreateCompanyRequest {
  goal: string[]
  logo_path: Url | null
  name: string
  exchange_role: string
  employee_amount?: string | null
  order_amount: string
  fleet_amount: string | null
  website?: string | null
  email?: string | null
  phone?: string | null
  company_form: string,
  registration_country_id: string,
  legal_form_short: string,
  legal_name: string,
  registration_number: string,
  tax_number?: string | null,
}


//TODO extend from Company
export interface UpdateCompanyRequest {
  name?: string
  owner_id?: string
  custom_cover_path?: string
  nickname?: string
  company_form?: string
  registration_country_id?: string
  legal_form_short?: string
  legal_name?: string
  registration_number?: string
  registration_date?: string | null
  tax_number?: string | null
  legal_address?: string
  mail_address?: string
  associations?: string[]
  is_transport_license?: boolean
  is_transport_license_company?: boolean | null
  transport_license_number?: string | null
  is_forwarder_license?: boolean
  is_forwarder_license_company?: boolean | null
  forwarder_license_number?: string | null
  momo?: string
  logo_path?: Url | null
  email?: string
  phone?: string
  website?: string
  exchange_role?: string
  foundation_year?: number
  employee_amount?: string | null
  order_amount?: string
  fleet_amount?: string | null
  goal?: string[]
  international_direction?: string[] | null
  national_direction?: string[] | null
  transportation_type?: string[] | null
  cargo_type?: string[] | null
  logistics_service?: string[]
  customs_service?: string[] | null
  is_ban?: boolean
  is_profile_public?: boolean
  description?: string
}

export interface DeleteCompanyRequest {
  reason: string
}

export interface Department {
  id: ID;
  name: string;
  company_id: Company['id'];
}

// TODO rename to CompanyRole
export interface Role {
  id: ID;
  name: string;
  company_id?: Company['id'];
}

export interface ContactDepartmentsData {
  id: ID
  name: string
  contact_count: number
}

export interface GetCompanyRequest {
  id: ID
}

export interface SendCompanyInvitationRequest {
  emails: string[]
  profile_ids: string[]
  role: string
  representative: boolean
}

export interface GetCompanyInvitationByHash {
  magic_hash: string
}

export interface CheckEmailInCompanyRequest {
  email: string
}

export interface AcceptInvitationRequest {
  device: AuthDevice
  magic_hash: string
  set_as_active?: boolean
}

export type AcceptInvitationByHashResponse = {
  company: Company,
  invitation: Partial<CompanyInvitation>,
  profile: Profile,
  auth_token: {
    token: string
    expires_at: string
  }
}

export type GetAllPublicCompanies = {
  params: PaginationWithPage
  body: Partial<{
    search: string
    exchange_role: Array<ExchangeRoleSlug>
    fleet_amount: Array<FleetAmountSlug>
    employee_amount: Array<CompanyEmployeeAmountSlug>
    foundation_year: Array<string>
    transportation_type: Array<TransportationTypeSlug>
    cargo_type: Array<CompanyCargoTypeSlug>
    logistics_service: Array<LogisticsServiceSlug>
    customs_service: Array<CustomsServiceSlug>
    international_direction: Array<Country["alpha2"]>
    national_direction: Array<Country["alpha2"]>
    registration_country: Array<Country["alpha2"]>

    order_amount: Array<OrderAmountSlug>
    company_form: Array<CompanyFormSlug>

    direction_or: boolean
  }>
}

export type GetAllPublicCompaniesExtended = {
  params: PaginationWithPage
  body: Partial<{
    search: string
    exchange_role: Array<ExchangeRoleSlug>
    fleet_amount: Array<FleetAmountSlug>
    employee_amount: Array<CompanyEmployeeAmountSlug>
    foundation_year: Array<string>
    transportation_type: Array<TransportationTypeSlug>
    cargo_type: Array<CompanyCargoTypeSlug>
    logistics_service: Array<LogisticsServiceSlug>
    customs_service: Array<CustomsServiceSlug>
    international_direction: Array<Country["alpha2"]>
    national_direction: Array<Country["alpha2"]>
    registration_country: Array<Country["alpha2"]>

    order_amount: Array<OrderAmountSlug>
    company_form: Array<CompanyFormSlug>
  }>
}


export interface createInvitationRequest {
  device_fingerprint?: string
}

export interface cancelAccountDeletionRequest {
  token: string
}

export type GetAllPublicCompaniesResponse = ItemsResponse<Company>

export type GetAllPublicCompaniesExtendedResponse = {
  total: any
  items: Company[]
  total_pages: number
}

export interface CompanyInternalInfo {
  slug: PartnerCompanySlugs | null
}

export enum PartnerCompanySlugs {
  Alpha = 'alfa_leasing'
}

export interface BusinessClientItemResponse {
  company_id: string,
  slug: PartnerCompanySlugs
}
