import { NoChatsPlaceholder as NoChatsPlaceholderIcon } from '@roolz/icons/chats/NoChatsPlaceholder'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './NoChatsPlaceholder.module.scss'

interface Props {
  style?: any
}

export const NoChatsPlaceholder = forwardRef(({
  style
}: Props, ref: any) => {
  const { t } = useTranslation('chat/list')

  return (
    <div
      className={styles.root}
      ref={ref}
      style={style}
    >
      <div className={styles.content}>
        <div className={styles.icon}>
          <NoChatsPlaceholderIcon size={200}/>
        </div>
        <div className={styles.label}>
          {t('no_chats_in_group')}
        </div>
      </div>
    </div>
  )
})
