import { Message } from '@roolz/types/api/chats'
import { useMemo } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MessageDate.module.scss'
import dayjs from '@roolz/sdk/plugins/dayjs'

interface Props {
  message: Message
}

export const MessageDate = ({
  message
}: Props) => {
  const { t } = useTranslation('ui')

  const dateFormatted = useMemo(() => {
    const date = dayjs(message.created_at)

    return date.format('HH:mm')
  }, [message.created_at])

  return (
    <div className={styles.date}>
      {dateFormatted}
    </div>
  )
}
