import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './UnreadMessages.module.scss'

interface Props {

}

export const UnreadMessages = forwardRef((props: Props, ref: any) => {
  const { t } = useTranslation('chat/message')

  return (
    <div
      className={styles.root}
      ref={ref}
    >
      <div className={styles.content}>
        {t('unread_messages')}
      </div>
    </div>
  )
})
