import { Delete } from '@roolz/icons/Delete'
import { useTranslation } from 'react-i18next'
import styles from './MessageDeletedContent.module.scss'

interface Props {

}
export const MessageDeletedContent = (props: Props) => {
  const { t } = useTranslation('chat/message')

 return (
  <div className={styles.content}>
    <Delete
      color='#8E8E93'
      size={12}
      className={styles.icon}
    /> {t('message_state.deleted')}
  </div>
 )
}
