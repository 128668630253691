import { chatsStore } from '@/store/chats/chats.store'
import { uiStore } from '@/store/ui/ui.store'
import { ChatModel } from '@/types/models/chat'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { Chat } from '@roolz/types/api/chats'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { forwardRef, memo, useCallback } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { ChatItem } from '../ChatItem/ChatItem'
import styles from './ChatVirtualList.module.scss'

interface Props {
  chats: ChatModel[]
  activeChatId?: Chat['id'] | null

  onChatSelect: (chat: ChatModel) => void
  onChatRightClick: (event: any, chat: ChatModel) => void

  className?: string
  style?: any
}

export const ChatVirtualList = observer(forwardRef(({
  chats,

  onChatSelect,
  onChatRightClick,

  className,
  style
}: Props, ref: any) => {
  const ITEM_HEIGHT = 55
  const OVERSCAN_WINDOWS_COUNT = 2

  const ChatRenderer = useCallback(observer(({
    data: chats,
    style,
    index
  }: any) => {
    const chat = chats[index]

    if(!chat) {
      return null
    }

    return (
      <ChatItem
        style={style}
        key={chat?.id ?? index}
        chat={chat}
        onChatClick={() => {
          onChatSelect(chat)
        }}
        onRightClick={(event: any) => onChatRightClick(event, chat)}
        showInfo={IS_MOBILE || !uiStore.isSidebarCollapsed}
      />
    )
  }), [onChatSelect, onChatRightClick])

  return (
    <div
      className={cn(styles.chats, className)}
      style={style}
      ref={ref}
    >
      <AutoSizer>
        {(size: { width: number, height: number }) => (
          <FixedSizeList
            className={styles.chats__list}

            height={size.height}
            itemData={chats}
            itemCount={chats.length}
            itemSize={ITEM_HEIGHT}
            width={size.width}
            overscanCount={Math.floor(size.height * OVERSCAN_WINDOWS_COUNT / ITEM_HEIGHT)}
          >
            {ChatRenderer}
          </FixedSizeList>
        )}
      </AutoSizer>

    </div>
  )
}))
