import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Typing.module.scss'

interface Props {

}
export const Typing = (props: Props) => {
  const { t } = useTranslation('chat/common')
  const [dots, setDots] = useState<number>(1)

  useEffect(() => {
    const timer = setInterval(() => {
      setDots(dots => dots < 3 ? dots + 1 : 1)
    }, 700)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return <>{
    t('status.typing')
    + Array(dots).fill('.').join('')
  }</>
}
