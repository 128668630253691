import { Loadable } from '@roolz/sdk/components/ui/Loadable/Loadable'
import styles from './ChatLoading.module.scss'

export const ChatLoading = () => {
  return (
    <div className={styles.loading}>
      <Loadable loading={true}/>
    </div>
  )
}
