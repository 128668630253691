import { observer } from 'mobx-react-lite'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './JoinPanel.module.scss'
import { ChatType, Chat } from '@roolz/types/api/chats'

interface Props {
  chatType: ChatType
  onJoin: () => void
}

export const JoinPanel = observer(({
  chatType,
  onJoin
}: Props) => {
  const { t } = useTranslation('chat/common')

  return (
    <button
      className={styles.main}
      onClick={onJoin}
    >
      {chatType === ChatType.CHANNEL
        ? t('join_channel')
        : chatType === ChatType.GROUP_CHAT
          ? t('join_group')
          : t('join')
      }
    </button>
  )
})
